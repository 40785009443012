import React, { memo, useState } from "react"
import InfoModal from "./InfoModal"
import { Button } from "antd"
import arrow from "../images/arrow.png"

const SimplifiedStepstogetyouStarted = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <div className="SimplifiedStepstogetyouStarted">
        <h1>Simplified Steps to get you Started</h1>
        <div className="div-container">
          <div class="div1">
            <h2>01</h2>
            <p className="underlined-paragraph">
              <strong>Assessment and Planning</strong>
            </p>
            <ul>
              <li>Define project objectives and goals.</li>
              <li>Assess organizational readiness for D365 implementation.</li>
              <li>Identify key stakeholders and form a project team.</li>
            </ul>
            <Button className="button-our" onClick={showModal}>
              Let’s Discuss
              <img src={arrow} width="20px" style={{ marginLeft: "4px" }} alt="Discuss arrow"/>
            </Button>
          </div>
          <div class="div2">
            <h2>02</h2>
            <p className="underlined-paragraph">
              <strong>Requirements Gathering</strong>
            </p>
            <ul>
              <li>Conduct a thorough analysis of business processes.</li>
              <li>
                Identify specific requirements and functionalities needed.
              </li>
            </ul>
          </div>
          <div class="div3">
            <h2>03</h2>
            <p className="underlined-paragraph">
              <strong>System Design</strong>
            </p>
            <ul>
              <li>Design the system architecture and infrastructure.</li>
              <li>
                Plan for data migration and integration with existing systems.
              </li>
            </ul>
          </div>
          <div class="div4">
            <h2>04</h2>
            <p className="underlined-paragraph">
              <strong>Customization and Configuration</strong>
            </p>
            <ul>
              <li>Customize D365 to align with business processes.</li>
              <li>Configure modules and settings based on requirements.</li>
            </ul>
          </div>
          <div class="div5">
            <h2>05</h2>
            <p className="underlined-paragraph">
              <strong>Data Migration</strong>
            </p>
            <ul>
              <li>
                {" "}
                Extract, transform, and load (ETL) data from legacy systems.
              </li>
              <li>Validate and cleanse data for accuracy and completeness.</li>
            </ul>
          </div>
          <div class="div6">
            <h2>06</h2>
            <p className="underlined-paragraph">
              <strong>Testing</strong>
            </p>
            <ul>
              <li>Conduct unit testing for individual components.</li>
              <li>
                Perform integration testing to ensure smooth interaction between
                modules.
              </li>
              <li> Implement user acceptance testing (UAT) with end-users.</li>
            </ul>
          </div>
          <div class="div7">
            <h2>07</h2>
            <p className="underlined-paragraph">
              <strong>Training</strong>
            </p>
            <ul>
              <li>Train end-users on D365 functionalities.</li>
              <li>
                {" "}
                Provide training for administrators on system maintenance.
              </li>
            </ul>
          </div>
          <div class="div8">
            <h2>08</h2>
            <p className="underlined-paragraph">
              <strong>Deployment</strong>
            </p>
            <ul>
              <li>Plan and execute the go-live strategy.</li>
              <li>Monitor system performance and address any issues.</li>
            </ul>
          </div>
          <div class="div9">
            <h2>09</h2>
            <p className="underlined-paragraph">
              <strong>Post-Implementation Support</strong>
            </p>
            <ul>
              <li>Provide ongoing support for users.</li>
              <li>Address any post-implementation issues.</li>
              <li>
                {" "}
                Evaluate and implement system enhancements based on feedback.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <InfoModal
        companyIsEnabled
        url="http://185.213.27.237:8066/Email/ContactUs"
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default memo(SimplifiedStepstogetyouStarted)
